import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/fontawesome-free-solid";
import Header from "../Layout/Header";
import HomepageBanner from "../UI/Banner/HomepageBanner";
import Footer from "../Layout/Footer";
import DoctorFacetSearch from "../UI/UIComponent/doctorUIComponent/DoctorFacetSearch";
import DoctorDetailCard from "../UI/UIComponent/doctorUIComponent/DoctorDetailCard";
import docDummyImage from "../assets/Images/Global/Doc-dummy-image.jpg";
import "./Page-style/doctor-consult.scss";
import Image from "../UI/UIComponent/Image";
import Button from "../UI/UIComponent/Button";
import smallBanner from "../assets/Images/Global/small-banner.png";
import DietPlanBanner from "../UI/Global-Component/DietPlanBanner";
import NonCarouselBanner from "../UI/Banner/NonCarouselBanner";
import PageBannerImage from "../assets/Images/DoctorConsultPage/Doc-spec-page-banner.jpg";
export default function DoctorConsultationPage() {
  return (
    <>
      <Header></Header>
      {/* <HomepageBanner></HomepageBanner> */}
      <section>
        <NonCarouselBanner
          ImageSrc={PageBannerImage}
          imageAlt="RHS doctor consultation"
        ></NonCarouselBanner>
        <div className="container-fluid">
          <div className="container doctor-detail">
            <h2>Top Doctors Available</h2>
            <DoctorFacetSearch></DoctorFacetSearch>
            <div className="body-section">
              <div className="card-stack">
                <DoctorDetailCard
                  Heading="Dr. Kalpana Dutta"
                  subHead="M.B.B.S"
                  detailLabelText="Language Known"
                  detailLabelValue="Hindi, Bengali, English"
                  para2LabelText="Next Available at:"
                  para2LabelValue="18:30PM"
                  rightSeclabel="Consultation Fee"
                  rightSeclabelVal="399"
                  primaryBtnText="View Profile"
                  secondaryBtnText="Book an Appointment"
                  imageVal={docDummyImage}
                ></DoctorDetailCard>
                <DoctorDetailCard
                  Heading="Dr. Kalpana Dutta"
                  subHead="M.B.B.S"
                  detailLabelText="Language Known"
                  detailLabelValue="Hindi, Bengali, English"
                  para2LabelText="Next Available at:"
                  para2LabelValue="18:30PM"
                  rightSeclabel="Consultation Fee"
                  rightSeclabelVal="399"
                  primaryBtnText="View Profile"
                  secondaryBtnText="Book an Appointment"
                  imageVal={docDummyImage}
                ></DoctorDetailCard>
              </div>

              <DietPlanBanner></DietPlanBanner>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </section>
    </>
  );
}
