import React from "react";
import Button from "../../../UI/UIComponent/Button";
import Image from "../../../UI/UIComponent/Image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid'; 
import "./staticBanner.scss";

export default function StaticBanner(props) {
  const bannerHeading = props.heading;
  const bannerStickerText = props.StickerText;
  const bannerDescription = props.descriptionText;
  const bannerFeatureText = props.featureList;
  const bannerCTAText = props.CTAText;
  const bannerImage = props.bannerImage;
  const bannerImageAltText = props.ImageAlt;
  return (
    <>
      <div className="doc-con-static-banner">
        <div className="left-section">
          <p className="banner-Heading">{bannerHeading}</p>
          <label>{bannerStickerText}</label>
          <p className="banne-desc">{bannerDescription}</p>
          <ul className="feature-list">
            {bannerFeatureText.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
          <div className="btn-wrapper">
            <Button primaryButton={false}>{bannerCTAText}<FontAwesomeIcon icon={faChevronRight} /></Button>
          </div>
        </div>
        <div className="right-section">
          <Image
            imageSrc={bannerImage}
            imageAltText={bannerImageAltText}
            imageClass="w-100"
          ></Image>
        </div>
      </div>
    </>
  );
}
