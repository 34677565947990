import React, { useEffect } from "react";
import doctorDateData from "../../StaticData/doctorPageData.json";
import Card from "../../UI/UIComponent/Card";
import '../../Page/Page-style/doctor-consult.scss';
import CallForAssistance from "../../UI/Global-Component/CallForAssistance";

export default function DoctorSpecialityComponent() {
  const DoctorSpecData = [];
  const imgPath = require.context('../../assets/Images/DoctorConsultPage/Speciality',true);
  useEffect(() => {
    //DoctorSpecData.push(doctorDateData)
    // fetch("http://localhost:3000/StaticData/StaticData/doctorPageData.json")
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     DoctorSpecData.push(data);
    //     console.log(DoctorSpecData);
    //   })
    //   .catch((error) => {
    //     console.error("There was a problem with the fetch operation:", error);
    //   });
  });
  
  return (
    <div className="container-fluid doctor-speciality-comp">
      <div className="container p-0">
        <div className="global-heading row">
          <div className="col-8 p-0">
            <h2>Consult Doctor By Speciality</h2>
            <span>Select Speciality to find relevant doctors.</span>
          </div>
        </div>
      </div>
      <div className="doctor-speciality-pool">
        {doctorDateData.map((data) => (
          <Card
            imagesrc={
                imgPath(`./${data.Speciality_Image}.jpg`)
            }
            imgAltText={data.Image_AltText}
            heading={data.Speciality_Text}
            ButtonClass='d-none'
            descriptionClass='d-none'
          ></Card>
        ))}
      </div>
      <CallForAssistance></CallForAssistance>
    </div>
  );
}
