const apiKey = process.env;
const apiUrl = apiKey.REACT_APP_API_URL

async function getOTP(payload) {
  
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }
    const response = await fetch(`${apiUrl}/getOTP`, requestOptions)
    const responseData = await response.json()
    return responseData
  } catch (error) {
    throw error
  }
}
async function submitPatientQuery(payload) {
    try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
        const response = await fetch(`${apiUrl}/submitPatientQuery`, requestOptions)
        const responseData = await response.json()
        return responseData
      } catch (error) {
        throw error
      }
}

module.exports = { getOTP, submitPatientQuery }
