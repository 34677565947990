import React from "react";
import homecareBanner from "../../assets/Images/Homepage/homecare-banner.png";
import homecareBannerMob from "../../assets/Images/Homepage/homecare-banner-mob.jpg";
import Button from "../../UI/UIComponent/Button";
import "./homecareBanner.scss";

export default function HomeCareStaticBanner() {
  return (
    <div className="homecare-banner">
      <img alt="RHS homecare banner" src={homecareBanner} className="banner-img w-100"></img>
      <img alt="RHS homecare banner" src={homecareBannerMob} className="banner-img-mob w-100"></img>
      <div className="banner-content container">
        <div className="row">
          <div className="col-12">
            <div className="heading">
              <span>
                Home is Where Healing Begins, Empowering Elderly Care.
              </span>
            </div>
            <div className="col-12">
              <div className="buttonSec">
                <Button primaryButton={false}>Consult Now</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
