import React from "react";
import "./appoinment.scss";

export default function HowtoBookDocAppoinment() {
  return (
    <div className="container-fluid">
      <div className="container hw-t-b-app">
        <h2>How to book doctor appointment?</h2>
        <div className="book-process">
          <div className="d-flex">
            <span className="number-plate">1</span>
            <span className="text-plate">
              Choose doctor doctor specialisation   
            </span>
          </div>
          <div className="d-flex">
            <span className="number-plate">2</span>
            <span className="text-plate">Check doctor's availability</span>
          </div>
          <div className="d-flex">
            <span className="number-plate">3</span>
            <span className="text-plate">Select Date & time slot</span>
          </div>
          <div className="d-flex">
            <span className="number-plate">4</span>
            <span className="text-plate">Book an Appointment</span>
          </div>
        </div>
      </div>
    </div>
  );
}
