import React, { useEffect, useState } from 'react'
import logo from '../../../RHS_logo.png'
import './commonModal.scss'
import Input from '../Input'
import Button from '../Button'
import SuccessGif from '../../../assets/GIF/sucess-gif.gif'
import { submitPatientQuery } from '../../../api/services/global-service'

export default function OtpModal(props) {
  const [isOtpValid, setOtpValidation] = useState(null)
  const [otpValue, setOtpValue] = useState()
  let formData

  formData = {
    patient_name: props.patientInfoPayload.patient_name,
    patient_email: props.patientInfoPayload.patient_email,
    patient_contactNo: props.patientInfoPayload.patient_contactNo,
    patient_prescription: props.patientInfoPayload.patient_prescription,
    patient_pin: props.patientInfoPayload.patient_pin,
    patient_city: props.patientInfoPayload.patient_city,
    query_type: props.patientInfoPayload.query_type,
    queryRaisedOn: props.patientInfoPayload.queryRaisedOn,
  }

  const handleClosePopup = () => {
    let modalRef = document.getElementById('otpModal')
    modalRef.classList.remove('show')
    setOtpValidation('')
  }
  const handleEnterOtpchange = (e) => {
    setOtpValue(e.target.value)
  }
  const handleClickVerifyBtn = async () => {
    // const encodedData = document
    //   .getElementById('enter_otp')
    //   .getAttribute('data-value');
    const encodedData = props.patientEncodedData
    const verificationState = await handleVerifyOtp(otpValue, encodedData)
    setOtpValidation(verificationState.Status)
  }
  const handleVerifyOtp = async (otp, encodedData) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ otp: otp, encodedString: encodedData }),
      }
      const response = await fetch(
        'http://localhost:4000/app/verifyOTP',
        requestOptions,
      )
      const responseData = await response.json()
      return responseData
    } catch (error) {
      throw error
    }
  }
  const submitFormData = async (payload) => {
    try {
      const sendPayload = {
        patient_name: payload.patient_name,
        patient_email: payload.patient_email,
        patient_contactNo: payload.patient_contactNo,
        patient_prescription: payload.patient_prescription,
        patient_pin: payload.patient_pin,
        patient_city: payload.patient_city,
        query_type: payload.query_type,
        queryRaisedOn: payload.queryRaisedOn,
      }
      // const response = await fetch(
      //   'http://localhost:4000/app/submitPatientQuery',
      //   requestOptions,
      // )
      // const responseData = await response.json()
      // return responseData
      const responseData = await submitPatientQuery(sendPayload);
      return responseData;
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    async function submitPatientQuery() {
      const data = await submitFormData(formData)
      console.log(data)
    }
    if (isOtpValid) {
      submitPatientQuery()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtpValid])
  return (
    <div
      class="modal fade common-modal"
      id="otpModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="otpModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <img src={logo} alt="Rever Health"></img>
            <button
              type="button"
              class="close"
              data-dismiss="commonModal"
              aria-label="Close"
              onClick={handleClosePopup}
            >
              {/* <span aria-hidden="true"></span> */}
            </button>
          </div>
          <div class="modal-body">
            <div className="content-wrapper text-center">
              <div className="heading">
                {!isOtpValid ? (
                  <span>Verify Your Contact Number</span>
                ) : (
                  <span>Your Query Submitted Successfully.</span>
                )}
              </div>
              <div className="otp-form">
                <div className="d-flex justify-content-between mb-3">
                  <>
                    {isOtpValid ? (
                      <></>
                    ) : (
                      <label className="lbl-text"> Please Enter OTP:</label>
                    )}
                  </>

                  <>
                    {isOtpValid === false ? (
                      <Button
                        type="button"
                        primaryButton={true}
                        data-dismiss="commonModal"
                        buttonClickHandler={handleClickVerifyBtn}
                        className="resend-btn"
                      >
                        Resend
                      </Button>
                    ) : (
                      ''
                    )}
                  </>
                </div>
                <input hidden id="enter_otp"></input>
                <>
                  {isOtpValid ? (
                    <></>
                  ) : (
                    <Input handleonChange={handleEnterOtpchange}></Input>
                  )}
                </>

                <>
                  {isOtpValid === false ? (
                    <span className="text-danger">Please Enter Valid OTP.</span>
                  ) : (
                    ''
                  )}
                </>
              </div>
              <>
                {isOtpValid ? (
                  <div className="success-section text-center">
                    <img src={SuccessGif} alt="success"></img>
                  </div>
                ) : (
                  ''
                )}
              </>
            </div>
            <div className="btn-section text-center">
              {isOtpValid ? (
                <></>
              ) : (
                <Button
                  type="button"
                  primaryButton={true}
                  data-dismiss="commonModal"
                  buttonClickHandler={handleClickVerifyBtn}
                >
                  Verify
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
