import { React, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {faWhatsapp,faFacebook,faInstagram} from  '@fortawesome/free-brands-svg-icons';
import { Link,NavLink } from "react-router-dom";
import logo from "../RHS_logo.png";
import "./header.scss";
import Button from "../UI/UIComponent/Button";
import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery.slim.min.js";
import "../../node_modules/popper.js/dist/popper.min.js";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";



export default function Header() {
  const navRef = useRef(null);
  //const currentAriaExpandVal = document.getElementById('navBarMenu').ariaExpanded;
  //const [hamburgerExpand, isHanburgerExpanded] = useState(currentAriaExpandVal);
  let expandFlg = '';
  const handletoggle = (e) =>{
    
   if(expandFlg === 'true') {
    e.target.parentElement.setAttribute('aria-expanded',false);
    expandFlg = 'false';
    setTimeout(() => {
      document.getElementById('navbarSupportedContent').classList.toggle('show');
    }, 500);
   }
   else{
    expandFlg = 'true';
   }
  }
  
  return (
    <>
      <header>
        <div className="offer-tab container-fluid">
        <span>
          Get 10% off on Doctor Appointment - <a href="/">Book Now</a>
            
          </span>
          <div className="right-section">
            <span>24/7 Emergency</span>
            <span>|</span>
            <span>
            <FontAwesomeIcon icon="fa-brands fa-whatsapp" /></span>
            <span><FontAwesomeIcon icon="fa-brands fa-facebook" /></span>
            <span><FontAwesomeIcon icon="fa-brands fa-instagram" /></span>
          </div>
        
          
        </div>
        <nav class="navbar navbar-expand-lg custom-navbar">
          <div class="container-fluid main-header">
            <a class="navbar-brand" href="/">
              <img src={logo} alt="Rever Health Solution" width="100px"></img>
            </a>
            <button
              class="navbar-toggler"
              type="button"
              id="navBarMenu"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handletoggle}
            > 
              <FontAwesomeIcon icon="fas fa-bars" />
              <FontAwesomeIcon icon="fas fa-window-close"/>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <Link className="nav-link active" to="/" eventKey="1">
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <Link id="id-about" className="nav-link" to="/about" eventKey="2">
                    About Us
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false" eventKey="3"
                  >
                    Services
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="/" eventKey="4">
                        Hospital
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/doctorsearch" eventKey="5">
                        Doctor
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/" eventKey="6">
                        Pathology & diagnostics
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/" eventKey="7">
                        Home Care
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/" eventKey="8">
                        Ambulance
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/" eventKey="9">
                        Medical Tourism
                      </Link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Health Tips
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    class="nav-link"
                    to="/"
                    tabindex="-1"
                    aria-disabled="true"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item ">
                  
                  <Button primaryButton={true}>Log In</Button>
                  
                </li>
              </ul>
              
            </div>
          </div>
        </nav>
        
      </header>
      
    </>
  );
}
