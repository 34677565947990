import React from "react";
import "./card.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Card(props) {
  return (
    <div className="custom-card">
      <div className="img-wrapper">
        <img src={props.imagesrc} alt={props.imgAltText} className="img-fluid"></img>
      </div>
      <div className="content-wrapper">
        <label className="heading">{props.heading}</label>
        <p className={props.descriptionClass}>{props.description}</p>
        <button className={props.ButtonClass} onClick={props.handleCallToaction}>{props.CTAText}<FontAwesomeIcon icon={props.fontIcon} /></button>
      </div>
    </div>
  );
}
