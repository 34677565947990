import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight,faRupeeSign } from "@fortawesome/fontawesome-free-solid";
import Image from "../UIComponent/Image";
import bannerImage from "../../assets/Images/Global/membership-banner.jpg";
import Button from "../UIComponent/Button";
import './membership-card.scss';

export default function MembershipCard() {
  const bannerHeading = "RHS Privilege Membership Card";
  const bannerSubHeading =
    "Dedicated services, special discounts and partner benefits";
  const bannerpara1 = `1) With this card, the members are entitled to get benefit from Pathology Radiology • Health Counselling Psychological Counselling ● Physiotherapy Green Therapy (Acupressure-Pain Managment) ● Doctor's Appointment (As Per Need) ● Hospital Emergency Admission● Home Care ● Technical Health Support ● Ambulance Service ● Medicine Home Delivery Dietician & Nutritionist.
    1) With this card, the members are entitled to get benefit from Pathology Radiology • Health Counselling Psychological Counselling ● Physiotherapy Green Therapy (Acupressure-Pain Managment) Doctor's Appointment (As Per Need) ● Hospital Emergency Admission● Home Care ● Technical Health Support ● Ambulance Service ● Medicine Home Delivery Dietician & Nutritionist.`;
  const bannerpara2 = `2)15% discount on pharmacy`;
  return (
    <div className="membership-card">
      <div className="banner-image">
        <Image
          imageSrc={bannerImage}
          imageAltText="RHS Image Alt Text"
          imageClass="w-100"
        ></Image>
        <div className="patch">
            <span>1 Year</span>
        </div>
      </div>
      <div className="banner-content container-fluid">
        <h2 className="banner-heading">{bannerHeading}</h2>
        <div className="subheading">
          <span>{bannerSubHeading}</span>
        </div>
        <div className="price-tag">
            <label className="old-price"><FontAwesomeIcon icon={faRupeeSign}></FontAwesomeIcon>399</label>
            <label className="new-price"><FontAwesomeIcon icon={faRupeeSign}></FontAwesomeIcon>200</label>
        </div>
        <div className="card-benifits">
          <p>{bannerpara1}</p>
          <p>{bannerpara2}</p>
        </div>
        <div className="btn-wrapper">
          <Button primaryButton={true}>
            <span>Apply Now</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </div>
      </div>
    </div>
  );
}
