import React from "react";
import logo from "../../../RHS_logo.png";
import "./commonModal.scss";

export default function CommonModal() {
  const handleClosePopup = () =>{

    let modalRef = document.getElementById('commonModal');
    modalRef.classList.remove('show');
   

  }

  return (
    <div>
      <div
        class="modal fade common-modal"
        id="commonModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="commonModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <img src={logo} alt="Rever Health"></img>
              <button
                type="button"
                class="close"
                data-dismiss="commonModal"
                aria-label="Close"
                onClick={handleClosePopup}
              >
                {/* <span aria-hidden="true"></span> */}
              </button>
            </div>
            <div class="modal-body">
              <div className="content-wrapper text-center">
                <div className="heading">
                  <span>
                    Thank You For Showing Interest On Our Digital Platform.
                  </span>
                </div>
                <div className="description">
                  <span>Kindly reach out to us by below contact number.</span>
                </div>
                <div className="phone-numbers">
                  <a href="tel:8116839460">8116839460</a>
                  <a href="tel:6290474807">6290474807</a>
                  <a href="tel:7595041562">7595041562</a>
                  <a href="tel:7595041563">7595041563</a>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="custom-primary-btn" data-dismiss="commonModal" onClick={handleClosePopup}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
