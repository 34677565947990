import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/fontawesome-free-solid";
import Image from "../../UI/UIComponent/Image";
import Button from "../../UI/UIComponent/Button";
import smallBanner from "../../assets/Images/Global/small-banner.png";
import "./dietPlanBanner.scss";

export default function DietPlanBanner() {
  return (
    <div className="small-banner">
      <div className="img-wrapper">
        <Image
          imageSrc={smallBanner}
          imageAltimageAltText="RHS provides custom diet plan"
        ></Image>
      </div>
      <div className="content-wrapper">
        <div className="para">
          <div className="head-text">
            <span >
              Unlock your Health Goal with a custom 7-Day Diet Plan by a
              Certified Dietitian, Price at Just
            </span>
            <span>
              <FontAwesomeIcon icon={faRupeeSign}></FontAwesomeIcon>499!
            </span>
          </div>
          <label>
            Diet Consultation
            <FontAwesomeIcon icon={faRupeeSign}></FontAwesomeIcon>499 Only!
          </label>
          <ul>
            <li>With Over 50+ Expert Dietitian</li>
            <li>Get Personalized diet plan</li>
          </ul>
        </div>
        <div className="btn-wrapper">
          <Button primaryButton={true}>Book Now</Button>
        </div>
      </div>
    </div>
  );
}
