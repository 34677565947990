import React from "react";
import "./input.scss";

export default function Input(props) {
  return (
    <input
      type={props.inputType}
      className={`input-Field ${props.isInputValid === false ? 'input-error' : ''}`}
      id={props.inputId}
      placeholder={props.inputPlaceholder}
      onChange={props.handleonChange}
      onBlur={props.handleInputBlur}
      disabled={props.isDisabled}
      onClick={props.handleInputClick}
    ></input>
  );
}
