import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign } from "@fortawesome/fontawesome-free-solid";
import Image from "../Image";
import Button from "../Button";
import './doctorDetail.scss';


export default function DoctorDetailCard(props) {
  const heading = props.Heading;
  const subHead = props.subHead;
  const detailLabel = props.detailLabelText;
  const detailLabelVal = props.detailLabelValue;
  const detailLabelNext = props.para2LabelText;
  const detailLabelNextVal = props.para2LabelValue;
  const image = props.imageVal;
  const imageAlt = props.alt;
  const rightSectionLabelText = props.rightSeclabel;
  const rightSectionLabelval = props.rightSeclabelVal;
  const primaryBtnText = props.primaryBtnText;
  const secondaryBtnText = props.secondaryBtnText;

  return (
    <div className="doctorDetail-card">
      <div className="image-section">
        <Image imageSrc={image} imageAltimageAltText={imageAlt}></Image>
      </div>
      <div className="middle-section">
        <div className="heading">
          <span>{heading}</span>
        </div>
        <div className="subHead">
          <p>{subHead}</p>
        </div>

        <div className="para-1">
          <label>{detailLabel}</label>
          <p>{detailLabelVal}</p>
        </div>
        <div className="para-2">
          <label>{detailLabelNext}</label>
          <p>{detailLabelNextVal}</p>
        </div>
      </div>
      <div className="last-section">
        <div className="top-sec">
          <p className="label-text">{rightSectionLabelText}</p>
          <div className="price-tag">
            <label className="old-price"><FontAwesomeIcon icon={faRupeeSign}></FontAwesomeIcon>399</label>
            <label className="new-price"><FontAwesomeIcon icon={faRupeeSign}></FontAwesomeIcon>200</label>
        </div>
          {/* <p className="label-val">{rightSectionLabelval}</p> */}
        </div>
        <div className="btn-wrapper">
          <Button primaryButton={true}>{primaryBtnText}</Button>
          <Button primaryButton={false}>{secondaryBtnText}</Button>
        </div>
      </div>
    </div>
  );
}
