import { React, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomepageBanner from "../UI/Banner/HomepageBanner";
import BestDoctor from "../FeatureComponent/BestService/BestDoctor";
import DoctorSpecialityComponent from "../FeatureComponent/DoctorPageComponent/DoctorSpecialityComponent";
import StaticBanner from "../FeatureComponent/DoctorPageComponent/Banner/StaticBanner";
import staticBannerImage from "../assets/Images/DoctorConsultPage/doctorConsultationPage-StaticBanner.jpg";
import HowtoBookDocAppoinment from "../UI/Global-Component/HowtoBookDocAppoinment";
import QuickBooking from "../FeatureComponent/QuickBooking/Quickbooking";
import MembershipCard from "../UI/Global-Component/MembershipCard";
import Faq from "../UI/Global-Component/Faq";

export default function DoctorSearchPage() {
  const bannerHeadingText =
    "Unlock Your Health Goals with a Custom 7-Day Diet Plan by a Certified Dietitian, Priced at Just *499!";
  const bannerStaticText = "Facing Challenges in Achieving Your Health Goals?";
  const bannerDescText =
    "Discover the Solution to Your Health Goals with Our Dietitian Consultation Service!";
  const BannerFeatureList = [
    "Choose from Over 50+ Expert Dietitians to Guide You Towards Your Health Goals!",
    "Receive a Tailored 7-Day Diet Plan Customized to Your Demographics, Health, and Goals!",
    "Our Certified Dietitians Will Collaborate with You to Design a Meal Plan Tallored to Fit Your Lifestyle!",
  ];
  const BannerBtnText = "Book Appointment";
  const bannerImageAlt = "Book Health Checkups through RHS";

  const quickBookingHeading = "How to get the best out of your doctor?";
  const thingsToRemember = [
    {
      "label_text": "Be Prepared:",
      "para_text":
        "Before your appointment, jot down your symptoms, concerns, and any questions you have for your doctor. This ensures you cover all relevant points during your visit and make the most of your time together.",
    },
    {
      "label_text": "Open Communication:",
      "para_text":  "Share your medical history, lifestyle habits, and any recent changes in your health openly with your doctor. Honest communication helps them understand your needs better and tailor their advice and treatment accordingly.",
    },
    {
      "label_text": "Ask Questions:",
      "para_text":  "Don't hesitate to ask questions about your condition, treatment options, or any uncertainties you have. Understanding your health and treatment plan empowers you to make informed decisions and actively participate in your care.",
    },
    {
      "label_text": "Follow Through:",
      "para_text":  "After your appointment, follow your doctor's recommendations diligently, including taking prescribed medications, scheduling follow-up appointments, and adopting any lifestyle changes advised. Consistency and commitment to your health plan ensure optimal results and ongoing support from your doctor.",
    },
  ];
  useEffect(() => {
    const timerFunc = setTimeout(() => {
      let selectedNavItem = document.getElementsByClassName("nav-link active");
      selectedNavItem[0].classList.remove("active");
      document.getElementById("id-about").classList.add("active");
    });
    return () => {
      clearTimeout(timerFunc);
    };
  }, []);

  return (
    <>
      <Header></Header>
      <HomepageBanner></HomepageBanner>
      <DoctorSpecialityComponent></DoctorSpecialityComponent>
      <StaticBanner
        heading={bannerHeadingText}
        StickerText={bannerStaticText}
        descriptionText={bannerDescText}
        featureList={BannerFeatureList}
        CTAText={BannerBtnText}
        bannerImage={staticBannerImage}
        ImageAlt={bannerImageAlt}
      ></StaticBanner>
      <BestDoctor></BestDoctor>
      <HowtoBookDocAppoinment></HowtoBookDocAppoinment>
      <QuickBooking
        heading={quickBookingHeading}
        thingsToRemember={thingsToRemember}
      ></QuickBooking>
      <Faq></Faq>
      <MembershipCard></MembershipCard>
      <Footer></Footer>
    </>
  );
}
