import React from 'react'

const termsAndConditions = {
    terms_and_conditions: {
      sections: {
        '': {
          description:
            'This document (“Terms”) is an electronic record in terms of The Information Technology Act, 2000, and rules thereunder, as applicable, and the amended provisions pertaining to electronic records in various statutes, as amended from time to time by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.This document is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 as amended from time to time, which requires publishing of the rules and regulations, privacy policy, and Terms for access or usage of the website and its mobile application.',
        },
        '1.GENERAL': {
          description:
            'We, at Rever Health Solution, a company duly incorporated under the provisions of the Companies Act, 2013, having its registered office at [Insert Address], India (“RHS”, “We”, “Us”, “Our” “Company”) provide services to all individuals accessing or using our app and website (collectively “Platform”) for any reason (“You”, “Yours”, “User”) subject to the notices, terms, and conditions set forth in these terms and conditions (“Terms and Conditions”, “Agreement”, “Terms”), read with the Privacy Policy available here [Insert Privacy Policy Link].',
          sections: {
              "":{
                  conditions: [
                      'The domain name [www.reverhealthsolution.com], an internet-based portal, and a mobile application, is run, operated, and maintained by RHS. RHS may assign, transfer, and subcontract its rights and/or obligations under these Terms and Conditions to any third party, as it may deem fit, and you shall continue to be bound by these Terms and Conditions in the event of such assignment, transfer, or subcontracting.',
                      'Our Platform is operated, and services are provided in compliance with the laws in India and RHS shall not be liable to provide any Services availed by you in locations outside India. If you access our services from locations outside India, you do so at your own risk and you are solely liable for compliance with applicable local laws. The User agrees to use the service to authorize an individual and get the services from the third party on his/her behalf. Where you use any third-party website or the services of any third party, you may be subject to alternative or additional terms and conditions of use and privacy policies of the respective third party/s.',
                      'Any accessing or browsing of the Platform and using the Services (as defined in these Terms and Conditions) indicates your Agreement to all the terms and conditions in this Agreement. If you disagree with any part of the Terms and Conditions, then you may discontinue access or use of the Platform.',
                    ],
              }
           
          },
        },
        '2. ELIGIBILITY': {
          description:
            'When You use the Platform, You represent that You meet the following primary eligibility criteria:',
          sections: {
            '': {
              conditions: [
                'You are at least 18 years old or accessing the Platform under the supervision of a parent or guardian, who in such a case will be deemed as the recipient/end-user of the Services (as defined in these Terms and Conditions) for the purpose of these Terms and Conditions.',
                'If Your age is below that of 18 years, your parents or legal guardians can transact on behalf of You if they are registered users. You are prohibited from purchasing any material the sale or purchase of which to/by minors is prohibited and which is for consumption by adults only.',
                'You are legally competent to contract, and otherwise competent to receive the Services (as defined in these Terms and Conditions). Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents, etc. are not eligible to use the Platform.',
                'You have not been previously suspended or removed by RHS or any other entity or disqualified for any other reason, from availing the Services.',
                'RHS reserves the right to terminate your membership and/or refuse to provide You with access to the Platform if RHS discovers that You are under the age of 18 years.',
                'Your registered id can only be utilized by You wherein Your details have been provided and We do not permit multiple persons to share a single login/registration id.',
                'We reserve our rights to refuse access to use the Services offered at the Platform to new Users or to terminate access granted to existing Users at any time without assigning any reasons for doing so and you shall have no right to object to the same.',
                'Organizations, companies, and businesses may not become registered Users on the Platform or use the Platform through individual Users.',
                'You agree and acknowledge that You shall (i) create only 1 (one) account; (ii) provide accurate, truthful, current, and complete information when creating your account and in all Your dealings through the Platform; (iii) maintain and promptly update your account information; (iv) maintain the security of Your account by not sharing Your password with others and restricting access to Your account and Your computer; (v) promptly notify RHS if You discover or otherwise suspect any security breaches relating to the Platform; and (vi) take responsibility for all the activities that occur under Your account and accept all risk of unauthorized access.',
                'We at Our discretion, reserve the right to permanently or temporarily suspend Users, to bar their use and access of the Platform, at any time while We investigate complaints or alleged violations of these Terms of Use or any Services, or for any other reason.',
              ],
            },
          },
        },
        '3. OUR SERVICES': {
          description: 'Through the Platform, We provide You with the following services (“Services”):',
            sections:{
              "1. Creating and Maintaining User Accounts:":{
              "conditions":[
                  "Users need to register on the Platform in order to use the Platform or the Services provided through the Platform. In order to register, You have to provide certain personal details including but not limited to Your name, phone number, email address, birth date, gender, etc."
              ]
            },
            "2.Scheduling an Appointment for Medical Consultation:":{
              "conditions":[
                  "You can book an appointment for a medical consultation with a healthcare or wellness service provider/doctor/physician (“HSP”) listed on the Platform. Medical consultations on the Platform shall be available for multi specialties.",
                  "You will receive a confirmation of appointment for medical consultation with a HSP, on the Platform and/or via SMS and/or email / online communication messaging services.We reserve the right to reschedule or cancel an appointment without any prior notice. The time provided for a consultation to you is indicative and actual consultation time may change depending on the consulting HSP’s discretion or other circumstances, for which You shall be accordingly communicated.You agree that there might be technological/logistic/unforeseen circumstances that might lead to delay or cancellation of the consultation for which appropriate resolution either as rescheduling/ refund shall be provided. For any support related to issue/s faced by You pre/during / post consultation,You can reach Us through various channels as set out on the Platform or the detail as set out in Section 11.8 of this Terms, and Our team shall help You for the same.",
                  "Consultations can be booked, rescheduled, or cancelled free of cost up to 15 (fifteen) minutes prior to the scheduled /booked appointment time for an audio/video/chat-based consultation (“Online Medical Consultation”) and up to 30 (thirty) minutes prior to the scheduled/booked the appointment time for a physical visit based consultation. You are allowed to reschedule an Online Medical Consultation up to a maximum of 3 (three) times for free and are allowed to reschedule a physical consultation once for free.",
              "Your consulting HSP reserves the right to provide a free review, post-medical consultation."
              ]
            },
            "3. Holding Medical Consultations:":{
              "conditions":[
              "You can have an Online Medical Consultation via audio call or video call or chat on the Platform with the HSP selected by You /allocated to You:",
              " Please ensure that You are equipped with proper video conference equipment and internet connectivity as required for the Service You want to avail. In case of inadequate video conference equipment, technical error, or defective internet connectivity, We reserve the right to reschedule or cancel Your appointment without any notice.",
              "You understand and acknowledge that there will not be any physical examination involved and the Online Medical Consultation services shall be provided remotely in accordance with these Terms and Conditions. The opinion delivered by the HSP shall solely be based on the verbal communication between the concerned HSP and the patient, and any test reports and other information provided/uploaded by You on the Platform.",
              "Your medical records may be viewed by the HSP on the chat facility provided on the Platform in the course of medical consultations and will remain accessible to You for a specific number of days after a particular consultation concludes, as determined by the HSP.",
              "The Services are not meant in any way for emergency and life-threatening conditions. It is advised to take the patient to the nearest hospital in such cases or as advised by HSP.",
              "In case the HSP is not responding You are advised to get in touch with our customer care."
            ]
            },
            "4. Receiving E-Prescription from the HSP Based on the Medical Consultation:":{
              "conditions":[
                  " You shall receive an e-prescription on the Platform after consultation with the concerned HSP."
              ]
                  
              },
              "5. Accessing Your Medical Records on the Platform:":{
                  "conditions":[
                      "You can access your medical records on the Platform."
                  ]
              },
              "6. Diagnostics Services:":{
                  "conditions":[
                      "You may book an appointment for seeking the diagnostic tests and packages offered by Rever Health Solution on the Platform. You may book diagnostic test(s) and package(s) on the Platform and visit the concerned diagnostics centres / lab of Rever Health Solution or schedule the sample pickup from home by Rever Health Solution."
                  ]
              },
              "7. Any Other Services:":{
                  "conditions":[
                      "That is made available on the Platform from time to time subject to the scope of Services specified under Section 4 below."
                  ]
              }
            }
            
  
            },
            
        
        '4. YOUR USE OF THE PLATFORM': {
          description:
            'As an end-user and recipient of Services, when You use the Platform, You agree to the following conditions of use:',
          sections: {
            'A. Due Diligence Conditions': {
              conditions: [
                'You are solely responsible for the medical, health, and personal information you provide on the Platform, and You are requested to use Your discretion in providing such information.',
                'The advice or the Services provided by the HSP will depend upon the information You provide on the Platform. You will provide accurate and complete information everywhere on the Platform, based on which You will receive the Services.',
                'You will be solely responsible for all access to and use of this site by anyone using the password and identification originally assigned to you whether or not such access to and use of this site is actually authorized by you, including without limitation, all communications and transmissions and all obligations (including, without limitation, financial obligations) incurred through such access or use. You are solely responsible for protecting the security and confidentiality of the password and identification assigned to You.',
                'The information provided by You may be used by us for the purpose of Services including analysis, research, training, and disclosure (where required) to our affiliates, group companies, agents and government authorities, etc., as stated in Our Privacy Policy (available at https://www.reverhealthsolution.com/terms). Please separately review and consent to Our Privacy Policy, accordingly.',
                'The information provided by You can be retained by us and can be used without revealing Your identity, as per Our Privacy Policy available at https://www.reverhealthsolution.com/terms.',
                'We reserve Our rights to refuse Service or terminate accounts at Our discretion, if we believe that You have violated or are likely to violate applicable law or these Terms and Conditions.',
              ],
            },
            'B. Scope of Services': {
              conditions: [
                'The Services availed by You from a HSP via the Platform are an arrangement between You and the HSP You select. The Platform only facilitates connections between You and the HSP and bears no responsibility for the outcome of any such medical consultation or other Services obtained by You.',
                'We shall not be liable for deficiency or shortfall in Services/misdiagnosis / faulty judgment/interpretation error/perception error / adverse events/inefficacy of prescribed treatment or advice or investigation reports/validity of the advice or prescription or investigation reports provided by the HSP / unavailability of the recommended or prescribed treatment or medication under any condition or circumstances. Users are advised to use their discretion for following the advice obtained post-medical consultation with HSP via the Platform or post Services.',
                'We only facilitate the connections between You and the HSP established through the Platform and do not in any way facilitate, encourage, permit, or require you to contact the HSP outside the Platform. Any contact between You and the HSP through the Platform will be subject to these Terms and Conditions.',
                'If You are redirected to, or visit, any other website or app from the Platform, including for the purposes of browsing, or when the placement and/or fulfilment of an order of any product/service (or any other aspect related thereto) takes You to a different website/platform, We will not be responsible for products or services received or purchased by You on such other website or platform, or any aspect of your experience on such website or any representations and details provided on such website/platform concerning products/services being displayed or sold by such website/platform. The entire user experience and data pertaining to Your usage of the RHS Platforms would be stored exclusively by RHS on its own servers. By visiting the RHS Platforms, You agree to and accept the terms of use of the RHS Platforms. We would not be involved in any interaction between You and RHS including any payments made by You in respect of the products purchased on the RHS Platforms and all products purchased by You on the RHS Platforms will be sold, packaged, dispensed, delivered, and fulfilled solely by RHS.',
              ],
            },
            'C. Prohibitions': {
              conditions: [
                'You may not reproduce, distribute, display, sell, lease, transmit, create derivative works from, translate, modify, reverse-engineer, disassemble, decompile or otherwise exploit the Platform or any portion of it unless expressly permitted by Us in writing.',
                'You may not make any commercial use of any of the information provided on the Platform.',
                'You may not impersonate any person or entity, or falsely state or otherwise misrepresent your identity, age or affiliation with any person or entity.',
                'You may not upload any content prohibited under applicable law, and/or designated as “Prohibited Content” under Section 5.',
                'You may not contact or make any attempt to contact the concerned HSP for a consultation, follow up to a prior medical consultation or for any other reason outside the Platform via email, SMS, messaging services or any other mode of communication outside the authorized channels.',
                'You may not assign, transfer, or sub-contract any of your rights or obligations under these Terms or any related order for Products to any third party, unless agreed upon in writing by Us.',
              ],
            },
          },
        },
        '5. PROHIBITED CONTENT': {
          description:
            'You shall not upload to, distribute, or otherwise publish through the Platform, the following Prohibited Content, which includes any content, information, or other material that:',
          conditions: [
            'belongs to another person and which you do not own the rights to;',
            "is harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, invasive of another's privacy, including bodily privacy, insulting or harassing on the basis of gender, libelous, racially or ethnically objectionable, or otherwise inconsistent with or contrary to the laws in force;",
            'is hateful, racially or ethnically objectionable, disparaging of any person;',
            'relates to or seems to encourage money laundering or gambling;',
            'harms minors in any way;',
            'infringes any patent, trademark, copyright, or other proprietary rights;',
            'violates any law in India for the time being in force;',
            'deceives or misleads the addressee about the origin of your message and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;',
            'communicates any information which is grossly offensive or menacing in nature;',
            'impersonates another person;',
            'contains software viruses or any other computer code and malicious programs;',
            'file or program designed to interrupt, destroy or limit the functionality of any computer resource;',
            'threatens the unity, integrity, defence, security, or sovereignty of India, friendly relations with foreign states, or public order, promoting enmity between different groups on the grounds of religion or caste with the intent to incite violence;',
            'is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person;',
            'incites any offence or prevents investigation of any offence or insults any other nation;',
            'is abusive or inappropriate to the HSP conducting your medical consultation or any employees, consultants or technicians of Rever Health Solution or any other affiliated group company who you may interact with for availing Services; and',
            'is not relating to the medical consultation or relating to any of the services you avail from us.',
          ],
          note:
            'You also understand and acknowledge that if you fail to adhere to the above, we have the right to remove such information and/or immediately terminate your access to the Services and/or to the Platform.',
        },
        '6. INDEMNITY': {
          description:
            'You agree and undertake to indemnify and keep indemnified Rever Health Solution, its affiliates, and any associated group companies, the concerned HSP, representatives, and each of their respective directors, officers, or employees (“Indemnified Persons”) and us for any losses, costs, charges, and expenses including reasonable attorney fees that the concerned Indemnified Persons may suffer on account of:',
          conditions: [
            'deficiency or shortfall in Services/misdiagnosis / faulty judgment/interpretation errors/perception error arising from:',
            'your failure to provide correct and/or complete clinical information/history about the patient in a timely and clinically appropriate manner; or',
            'suppression of material facts; or your failure to provide relevant clinical information about the patient; or',
            'misinterpretation of the advice/prescription/diagnosis/investigation report by you; or',
            'failure to follow doctor’s advice/prescription by you; or',
            'failure to follow instructions of the HSP in respect of the Services or medical procedures prescribed by the HSP by you;',
            'incorrect or inaccurate credit/debit card details provided by you; or',
            'using a credit/debit card which is not lawfully owned by you; or',
            'you permitting a third party to use your password or other means to access your account.',
          ],
        },
        '7. LIMITATION OF LIABILITY': {
          description:
            'By using our Services, you confirm that you understand and agree to the following:',
          conditions: [
            '- The Services availed by you from an HSP via the Platform are provided to you by the HSP you select, and not by Rever Health Solution. The limitation of liability specified in this section also applies to any Services availed by you from any third-party seller of services listed on the Platform.',
            '- The Platform only facilitates communications between you and the HSP and as such Rever Health Solution bears no responsibility for the quality and outcome of any such Services obtained by you from the respective HSP, to the extent permitted by applicable law.',
            '- Rever Health Solution itself does not provide any medical consultation or diagnostic services. If you receive any medical advice/investigation reports from an HSP you have contacted through the Platform, you are responsible for assessing such advice, and reports the consequences of acting on such advice or reports, and all post-consultation follow-up action, including following the HSP’s instructions.',
            '- In the event that Rever Health Solution markets or promotes any Services to you, please note that such Services will be provided by the relevant HSP, and you are responsible for undertaking an assessment regarding the suitability of such Services and such HSPs for your purposes. Marketing or promotion of Services should be considered as being for informational purposes only, and does not constitute expert advice on the suitability of such services for your specific healthcare needs.',
            '- The Services provided through the Platform are not intended in any way to be a substitute for face-to-face consultation with a doctor. Rever Health Solution advises the Users to make an independent assessment in respect of its accuracy or usefulness and suitability prior to making any decision in reliance hereof.',
            '- To the extent permitted by applicable law, Rever Health Solution or its affiliates or any associated group companies will not be liable to you for any special, indirect, incidental, consequential, punitive, reliance, or exemplary damages arising out of or relating to: (i) these Terms and Conditions and Privacy Policy; (ii) your use or inability to use the Platform; (iii) your use or inability to use the AI assistant; (iv) your use of any third-party services including Services provided by any HSP you contacted through the Platform.',
  
            'This section shall survive the termination of this Agreement and the termination of your use of our Services or the Platform.',
          ],
        },
        '8. DATA & INFORMATION POLICY': {
          conditions: [
            '8.1. We respect your right to privacy in respect of any personal information provided to us for the purposes of availing our Services. To see how we collect and use your personal information, please see our Privacy Policy available at Rever Health Solution Privacy Policy.',
            "8.2. Account Deletion: Users have the right to delete their Rever Health Solution account and personal information at any time, in line with Rever Health Solution's commitment to data privacy and applicable laws. Rever Health Solution will delete the user's data within 45 days of the account deletion request, and no further communications will be sent. Retrieval of deleted data will not be possible, except upon specific user request for account reactivation. Rever Health Solution will mark the phone number as 'inactive' after account deletion, allowing subsequent logins without data retrieval. Users can refer to an FAQ on the entire process, including how to delete an account and how to restore it on Rever Health Solution’s website.",
          ],
        },
        '9.INTELLECTUAL PROPERTY AND OWNERSHIP': {
          conditions: [
            '9.1. All Confidential Information, including the inherent intellectual properties (“IP”) which have been developed by Rever Health Solution or its affiliates or by third parties under contract to Rever Health Solution to develop the same or which have been purchased by or licensed to Rever Health Solution, remains the sole and exclusive property of Rever Health Solution.',
            '9.2. You shall be bound by the following obligations with respect to ownership of Intellectual Properties:',
            'All works, materials, software, documentation, methods, apparatus, systems, and the like prepared, developed, conceived, or delivered as part of or in connection with the Services and all tangible embodiments thereof, shall be considered IP for any use of Our Platform under this Agreement.',
            'Rever Health Solution shall have exclusive title and ownership rights, including all intellectual property rights, throughout the world in all Services. To the extent that exclusive title and/or ownership rights may not originally vest in Rever Health Solution as contemplated herein, You hereby irrevocably assign all rights, title, and interest, including intellectual property and ownership rights, in the Services, medical records, and information to Rever Health Solution, and shall cause Your representatives to irrevocably assign to Rever Health Solution all such rights in the IP.',
            'All uses of any trademarks, service marks, and trade names in the Services or in the performance of the Services, and the goodwill associated therewith, whether by You or third parties, inure and shall inure to the benefit of Rever Health Solution.',
            'You agree not to circumvent, disable or otherwise interfere with security-related features of the Platform or features that prevent or restrict use or copying of any materials or enforce limitations on use of the Platform or the materials therein. The materials on the Platform or otherwise may not be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted, or transmitted in any form or by any means, including but not limited to, electronic, mechanical, photocopying, recording, or other means.',
            'No use of these may be made without the prior written authorization of Rever Health Solution.',
          ],
        },
        '10. OTHER CONDITIONS': {
          conditions: [
            '10.1. Payment and Refund: Please note that your payments are processed in accordance with applicable laws as amended from time to time and You may refer to our Payment and Refunds Policy for details, available at Rever Health Solution Refund Policy.',
            '10.2. AI Assistant: We use an AI Assistant to guide patients seeking medical help or treatment from the Platform. This AI Assistant’s primary purpose is to allow users to book appointments by letting them enter their symptoms, medical conditions, or treatment that they are seeking to consult with an HSP.',
            'You understand that:',
            'You shall never use the AI assistant in a medical or psychiatric emergency',
            'In case of an emergency, you should dial 112 or visit the emergency department at the nearest hospital',
            'You can use this Platform on behalf of other users (third parties) only if you are a legal guardian of such persons, meaning that you have the legal authority to care for the personal and property interests of such persons',
            'No content on the Platform is, or should be considered, or used as a substitute for medical advice, care, diagnosis, or treatment',
            'The User may be directed to chat or call with our patient support team in certain cases which include but are not limited to the following:',
            'Uncertain outcome from the AI assistan',
            'Change in the schedule of appointments',
            'Incomplete appointment booking',
            'Any other case as deemed appropriate by Rever Health Solution.',
            '10.3. Accuracy of Information Displayed: We have made every effort to display, as accurately as possible, the information provided by the relevant third parties including HSPs. However, We do not undertake any liability in respect of such information and or with respect to any other information in regard to which you are capable of conducting Your own due diligence to ascertain accuracy.',
          ],
        },
        '11. MISCELLANEOUS': {
          conditions: [
            '11.1. THIRD-PARTY LINKS AND RESOURCES Where the Platform contains links to other sites and resources provided by third parties (including where our social media sharing plug-ins include links to third-party sites), these links are provided for your information only. We have no control over the contents of those websites/platforms or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them.',
            'Rever Health Solution is neither guaranteeing nor making any representation with respect to the goods or services made available or sold by such third-party. Rever Health Solution does not provide any warranty or recommendation in relation to the products and/or services made available to you by such third parties during your access or use of such third-party website/platform including in relation to delivery, services, suitability, merchantability, reliability, availability, or quality of the products and/or services.',
            'You shall not hold Rever Health Solution, its group entities, affiliates, or their respective directors, officers, employees, agents, and/or vendors responsible or liable for any actions, claims, demands, losses, damages, personal injury, costs, charges, and expenses which you claim to have suffered, sustained, or incurred, or claim to suffer, sustain or incur, directly or indirectly, on account of your use or access of the third-party website/platform.',
            'Rever Health Solution is not a party to any contractual arrangements entered into between you and the third-party website/platform. We are not the agent of the third party and such third-party website/platform is governed exclusively by its respective policies over which Rever Health Solution has no control.',
            'The use of such a link to visit the third-party website/platform implies full acceptance of these Terms and Conditions. Rever Health Solution shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content available on or through any such third-party linked',
            'website, including without limitation any form of transmission received from any third-party website or its server.',
            '11.2. AMENDMENTS We may from time to time update or revise these Terms and Conditions. Every time you wish to use the Platform, please check the relevant Terms and Conditions and Privacy Policy to ensure you understand the terms that apply at that time.',
            '11.3. FORCE MAJEURE We will not be liable for any non-compliance or delay in compliance with any of the obligations we assume under any contract when caused by events that are beyond our reasonable control (“Force Majeure”). Force Majeure shall include any act, event, failure to exercise, omission, or accident that is beyond our reasonable control, including, among others, the following:',
            'Strike, lockout, or other forms of protest',
            'Civil unrest, revolt, invasion, terrorist attack, or terrorist threat, war (declared or not) or threat or preparation for war',
            'Fire, explosion, storm, flood, earthquake, collapse, epidemic, or any other natural disaster',
            'Inability to use public or private transportation and telecommunication systems',
            'Acts, decrees, legislation, regulations, or restrictions of any government or public authority including any judicial determination',
            'Our obligations deriving from any contracts should be considered suspended during the period in which Force Majeure remains in effect and we will be given an extension of the period in which to fulfill these obligations by an amount of time we shall communicate to you, not being less than the time that the situation of Force Majeure lasted.',
            '11.4. TERMINATION 11.4.1. We may terminate this arrangement at any time, with or without cause. If you wish to terminate this arrangement, you may do so at any time by discontinuing your access or use of this Platform.',
            '11.4.2. We reserve the right to refuse the use of Services immediately in case your conduct is deemed by us to be in contravention of applicable acts, laws, rules, and regulations or these Terms and Conditions or considered to be unethical/immoral',
            '11.4.3. For change in law specifically, we reserve our rights to suspend our obligations under any contract indefinitely, and/or provide Services under revised Terms and Conditions',
            '11.5. APPLICABLE LAWS, JURISDICTION, AND DISPUTE RESOLUTION 11.5.1. The use of our Platform shall be governed by the laws applicable in India, without reference to the conflict of laws principles.',
            '11.5.2. Any dispute relating to the use of our Services shall be subject to the exclusive jurisdiction of the courts at Kolkata, India.',
            "11.5.3. If any dispute, difference, or claim arises between Us and You in connection with this Agreement or the validity, interpretation, implementation, or alleged breach of this Agreement or anything is done, omitted to be done pursuant to these Terms and Conditions, You shall first endeavor to resolve the same through conciliation and negotiation. However, if the dispute is not resolved through conciliation and negotiation within 30 days after the commencement of such conciliation or within such period mutually agreed in writing, then We may refer the dispute for resolution by arbitration under the Indian Arbitration and Conciliation Act, 1996 as amended from time to time ('the Act') by a sole arbitrator to be appointed mutually by Us. In the event the parties fail to agree on a sole arbitrator, a sole arbitrator shall be appointed in accordance with the Act. The seat and venue of arbitration will be in Chennai. The Arbitration shall be conducted in the English language.",
            '11.6. SEVERABILITY If, for any reason, a court of competent jurisdiction finds any provision of these Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the Parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.',
            '11.7. WAIVER No provision of these Terms of Use shall be deemed to be waived and no breach excused unless such waiver or consent shall be in writing and signed by Rever Health Solution. Any consent by Rever Health Solution to, or a waiver by Rever Health Solution of, any breach by Other Parties, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.',
          ],
        },
      },
  },
    }


export default function TermsAndConditions () {
  const renderConditions = (conditions) => {
    return (
      <ul>
        {conditions.map((condition, index) => (
          <li key={index}>{condition}</li>
        ))}
      </ul>
    )
  }

  const renderSections = (sections) => {
    return Object.keys(sections).map((sectionKey, index) => {
      const section = sections[sectionKey]
      return (
        <div key={index}>
          <h3>{sectionKey}</h3>
          <p>{section.description}</p>
          {section.conditions && renderConditions(section.conditions)}
          {section.sections && renderSubSections(section.sections)}
        </div>
      )
    })
  }

  const renderSubSections = (subSections) => {
    return Object.keys(subSections).map((subSectionKey, index) => {
      const subSection = subSections[subSectionKey]
      return (
        <div key={index}>
          <h4>{subSectionKey}</h4>
          {renderConditions(subSection.conditions)}
        </div>
      )
    })
  }

  return (
    <div>
      <h1>Terms and Conditions</h1>
      {renderSections(termsAndConditions.terms_and_conditions.sections)}
    </div>
  )
}

